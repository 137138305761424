@import '../_variables.scss';
@import '../_mixins.scss';

#pricing {
  padding: 5rem 0.5rem 10rem 0.5rem;
  min-height: calc(100vh - 5rem);
  position: relative;

  .star {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-family: $font-special;
      text-align: center;
      font-size: 5rem;
      margin-bottom: 5rem;
      color: $orange-color;
    }
  }

  .blue-square {
    position: absolute;
    width: 10rem;
    height: 7rem;
    background-color: #000;
    top: -2rem;
    right: 0;
    z-index: 1;
  }

  .package {
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    grid-template-columns: 200px 1fr 2fr;
    align-items: center;
    justify-items: start;
    border-collapse: collapse;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;

    & > div {
      padding: 1rem;
    }

    .package-image {
      img {
        width: 100%;
      }
    }

    .package-title-price-desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .package-title {
        font-weight: 600;
        font-size: 1.5rem;
        font-family: $font-special;
      }

      .package-description {
        font-size: 0.9rem;
        margin: 0.7rem 0;
      }
      .package-price {
        color: $orange-color;
        font-weight: 300;
        font-family: $font-special;
      }
    }

    .package-main {
      p {
        font-weight: 600;
      }
    }

    .package-includes {
      margin-top: 1rem;

      ul {
        padding-left: 15px;

        li {
          list-style-type: disc;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #pricing {
    padding: 5rem 2rem 3rem 2rem;

    .blue-square {
      width: 7rem;
      height: 7rem;
      top: -1.5rem;
      right: 0;
    }

    .package {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      .package-image,
      .package-title-price-desc {
        grid-column: span 1;
        grid-row: 1 / 2;
      }

      .package-image {
        width: 250px;
      }

      .package-main {
        grid-column: span 2;
        grid-row: 2 / 3;
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 480px) {
  #pricing {
    .star h1 {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    .package {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      .package-image,
      .package-title-price-desc,
      .package-main {
        grid-column: span 1;
        grid-row: auto;
        padding: 1rem 0;
      }

      .package-image {
        width: 100px;
      }
    }
  }
}

@keyframes rotateStar {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg); /* Rotate 90 degrees clockwise */
  }
  100% {
    transform: rotate(-270deg); /* Rotate 2 times counterclockwise */
  }
}
