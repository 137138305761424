@import './_variables.scss';
@import './_mixins.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &:hover {
    cursor: none;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  background: $grey-color;
  font-family: $font-text;
  color: $blue-color;
  cursor: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: 200;
}

p {
  font-weight: 200;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
