@import '../_variables.scss';
@import '../_mixins.scss';

header {
  position: sticky;
  z-index: 999;
  top: 0;
  background: #000;

  .header {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    color: #fff;
    align-items: center;
    justify-content: center;

    .brand {
      background: $blue-color;
      text-align: center;
      font-weight: 400;
      height: 100%;
      padding: 1rem 0;
      min-width: 200px;

      &:hover {
        color: #000;
      }
    }

    .navbar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0.5rem;

      a {
        font-size: 0.9rem;
        margin-right: 1.5rem;

        &:hover {
          color: $orange-color;
        }
      }
    }

    .contact {
      font-weight: 500;
    }

    .navbar-hamburger {
      display: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  /* Hamburger menu shows for small screens */
  @media (max-width: 660px) {
    .header {
      display: flex;
      align-items: center;
      padding: 0 1rem;

      .brand {
        width: auto;
        padding: 0.5rem 0;
        text-align: left;
        background: #000;
      }

      .navbar {
        display: none;
      }

      .navbar-hamburger {
        display: block;
        font-size: 1.5rem;
        cursor: pointer;
        position: absolute;
        right: 10px;
      }
    }
  }
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
    color: $orange-color;
  }

  .modal-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #fff;
    text-align: center;

    .modal-brand {
      font-size: 1.5rem;
      padding: 2rem;
    }

    .modal-links {
      display: flex;
      flex-direction: column;
      justify-content: center;

      a {
        margin: 1rem 0;
        font-size: 1.2rem;
        color: #fff;
        text-decoration: none;

        &:hover {
          color: $orange-color;
        }
      }
    }
  }
}
