@import '../_variables.scss';
@import '../_mixins.scss';

body {
  cursor: none;
}

#cursor-dot {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference; /* This will invert the colors when overlapping other elements */
}

#cursor-dot.invert {
  mix-blend-mode: difference; /* Ensures inversion */
  background-color: #ffffff; /* White to contrast with the difference blend mode */
}

.hover-target {
  display: inline-block;
  padding: 10px 20px;
  background: rgba(#fff, 0.5);
  color: white;
  border-radius: 20px;
  margin: 20px;
  font-family: Arial, sans-serif;
  font-size: 18px;
  position: relative;
  z-index: 0;
}

.hover-target:hover {
  cursor: none;
}
