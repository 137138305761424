@mixin fit-bg-image($vertical-adjustment: 0%) {
  background-position: center calc(50% + #{$vertical-adjustment}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

@mixin fit-image-with-adjustment($vertical-adjustment: 0%) {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center calc(50% + #{$vertical-adjustment});
}

@mixin color-hover {
  &:hover {
    background: $orange-color;
    color: $black-color;
    transition: background 0.3s ease, color 0.3s ease;
  }
}

@mixin blue-tint {
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $orange-color;
    mix-blend-mode: color;
    pointer-events: none;
    transition: opacity 0.3s ease;
    opacity: 0;
  }

  &:hover::before {
    opacity: 0.7;
  }
}
