@import '../_variables.scss';
@import '../_mixins.scss';

#home {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: calc(100vh - 4rem);
  max-width: 1200px;
  margin: auto;
  padding: 5rem 2rem;

  .hero {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
    grid-column: 3 / 4;

    h1 {
      font-size: 3rem;
      font-weight: 400;
      line-height: 1;

      strong {
        font-size: 6rem;
        font-family: $font-special;
      }
    }

    p {
      margin: 2rem 0;
      color: #000;
      font-size: 1.2rem;

      &:hover {
        color: $orange-color;
      }
    }

    a {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 400;
      margin-right: 1rem;
      @include color-hover;
    }
  }

  .img {
    grid-column: 2 / 5;
    background-position: top top;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 250px;
    min-width: 250px;
    z-index: 988;
  }
}

@media (max-width: 768px) {
  #home {
    display: flex;
    flex-direction: column;
    min-height: calc(50vh - 4rem);
    padding: 3rem 1rem;

    .hero {
      h1 {
        font-size: 2rem;

        strong {
          font-size: 3.5rem;
        }
      }

      p {
        font-size: 1rem;
      }

      a {
        font-size: 1rem;
      }

      .img {
        height: 250px;
        width: 250px;
        background-position: center top;
        background-size: cover;
      }
    }
  }
}

@media (max-width: 480px) {
  #home {
    padding: 2rem 1rem;

    .hero {
      h1 {
        font-size: 1.5rem;

        strong {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 0.9rem;
      }

      a {
        font-size: 1rem;
      }
    }
  }
}
