@import '../_variables.scss';
@import '../_mixins.scss';

#contact {
  max-width: 600px;
  margin: 5rem auto;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 2rem;

  h1 {
    color: $blue-color;
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center;
  }

  p {
    color: #000;
    text-align: center;
  }

  form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      label {
        font-weight: 400;

        span {
          font-size: 0.8rem;
          color: #888;
        }
      }

      input,
      textarea {
        padding: 1rem;
        font-size: 1rem;
        border: 1px solid #ccc;

        &::placeholder {
          color: #ccc;
        }

        &:focus {
          outline: none;
          border-color: #333;
        }
      }

      textarea {
        resize: vertical;
        height: 10rem;
      }

      .name-fields {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        input {
          flex: 1;
        }
      }
    }

    button {
      width: 100%;
      padding: 1.5rem;
      font-size: 1rem;
      color: #fff;
      background-color: #000;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #333;
      }
    }
  }
}

@media (max-width: 600px) {
  #contact {
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 0.9rem;
    }

    form {
      .form-group {
        label {
          font-size: 0.9rem;
        }

        input,
        textarea {
          padding: 0.5rem;
          font-size: 0.9rem;
        }

        textarea {
          height: 5rem;
        }

        .name-fields {
          flex-direction: column;
        }
      }

      button {
        padding: 1rem;
        font-size: 0.9rem;
      }
    }
  }

  .name-fields {
    flex-direction: column;
  }
}
