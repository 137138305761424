@import '../_variables.scss';
@import '../_mixins.scss';

.copyrights {
  text-align: center;
  font-weight: 200;
  padding: 0.5rem 0 1rem 0;

  &:hover {
    font-weight: 600;
  }
}
