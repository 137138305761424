@import '../_variables.scss';
@import '../_mixins.scss';

.animation-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotateAnimation 2.5s infinite;

  .shape {
    width: 100%;
    height: 100%;
    background-color: $orange-color;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
