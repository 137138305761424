.typed-cursor {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 10rem;
  font-family: 'NeueMachina', sans-serif;
  line-height: 0.5;
  display: flex;
  align-items: flex-end;
  max-width: 70%;

  @media (max-width: 768px) {
    font-size: 4.5rem !important;
    grid-column: 1 / -1;
    writing-mode: horizontal-tb;
    text-orientation: initial;
    line-height: normal;
    font-size: 7rem;
    align-items: center;
    justify-content: center;
  }
}

.cursor {
  animation: blink 0.7s infinite;
  padding-left: 0.2rem;
  margin-bottom: -5em;

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding-left: 0;
  }
}
