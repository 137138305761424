@import '../_variables.scss';
@import '../_mixins.scss';

#about {
  padding: 10rem 1.5rem;
  background: $blue-color;
  color: #000;
  position: relative;

  .about-content {
    max-width: 800px;
    margin: 0 auto;

    h1 {
      font-family: $font-special;
      font-size: 5rem;
      clear: both;
      white-space: nowrap;

      span {
        display: inline-block;
        white-space: normal;
      }
    }

    h2 {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 1;
      clear: both;
      margin-bottom: 2rem;
    }

    p {
      text-align: justify;
      line-height: 1.6;
      font-weight: 400;

      &:first-of-type {
        grid-column: 1 / -1;
      }

      &:last-of-type {
        grid-column: 1 / -1;
        font-weight: 400;
      }

      &:first-of-type {
        font-weight: 400;
      }

      &:first-of-type::first-letter {
        float: left;
        font-size: 3em;
        line-height: 1;
        margin-right: 0.1em;
        font-weight: bold;
      }
    }

    .about-text {
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 1fr;

      &:first-child {
        grid-column: -1;
      }
    }

    .image-wrapper {
      padding: 1rem;
      max-width: 300px;
      display: flex;
      margin: 3rem auto 0 auto;
      transition: transform 0.3s ease;

      img {
        width: 100%;
        height: auto;
        display: block;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .circle-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 250px;
      height: 250px;

      .circle {
        width: 300px;
        height: 300px;
        position: absolute;
        border-radius: 50%;
        background: $grey-color;
        bottom: 5rem;
      }
    }
  }
}

@media (max-width: 768px) {
  #about {
    .about-content {
      h1 {
        font-size: 3rem;
        text-align: center;
      }

      h2 {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
      }

      p {
        font-size: 0.8rem;
        text-align: left;
      }

      .about-text {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .image-wrapper {
        max-width: 250px;
      }

      .circle-container {
        width: 200px;
        height: 200px;

        .circle {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #about {
    padding: 3rem 2rem;

    .about-content {
      h1 {
        font-size: 2rem;
        z-index: 300;
      }

      h2 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.7rem;
        line-height: 1.4;
      }
      .about-text {
        gap: 0.8rem;
      }
      .image-wrapper {
        max-width: 200px;
      }

      .circle-container {
        top: rem;
        right: 0;
        width: 50px;
        height: 50px;
        z-index: 100;
      }
    }
  }
}
