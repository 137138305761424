@import '../_variables.scss';
@import '../_mixins.scss';

.scrolling-container {
  grid-column: 1 / 4;
  max-width: 100%;

  img {
    width: 100%;
  }
}
