@import '../_variables.scss';
@import '../_mixins.scss';

#testimonials {
  padding: 5rem 2rem;
  background-color: #fff;

  .testimonial-meta {
    max-width: 1200px;
    margin: 5rem auto;
    position: relative;

    .testimonial-head {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
      }

      h2 {
        padding-left: 5rem;
        font-size: 5rem;
        font-weight: bold;
        color: #000;
        font-family: $font-special;
      }
    }

    .arrows {
      visibility: hidden;
    }

    .opening-quote {
      position: absolute;
      font-size: 5rem;
      color: #000;
      top: 10rem;
      left: 0;
    }

    .closing-quote {
      position: absolute;
      font-size: 5rem;
      bottom: -10rem;
      right: 0;
    }

    .testimonials-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;

      .testimonial-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
        padding: 1rem;

        .testimonial-quote {
          font-size: 4rem;
          color: #000;
          position: absolute;
          top: -1.5rem;
          left: -1.5rem;
        }

        .testimonial-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 1.5rem;
        }

        .testimonial-text {
          font-size: 1rem;
          color: #333;
          margin-bottom: 2rem;
          line-height: 1.5rem;
          text-align: left;

          &:hover {
            font-weight: 400;
            color: $orange-color;
          }
        }

        .testimonial-author {
          margin-top: auto;

          .author-name {
            font-size: 1.2rem;
            font-weight: bold;
            color: #000;
            margin-bottom: 0.5rem;
          }

          .author-title {
            font-size: 0.9rem;
            color: #777;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .closing-quote {
    font-size: 4rem;
    color: #000;
    text-align: right;
    margin-top: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: 768px) {
  #testimonials {
    padding: 2rem;

    .testimonial-meta {
      margin: 0;

      .arrows {
        visibility: visible !important;
        display: flex !important;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        pointer-events: none; // Only arrows should be clickable

        .arrow {
          pointer-events: auto;
          background-color: rgba(0, 0, 0, 0.5);
          color: #fff;
          border: none;
          padding: 1rem;
          cursor: pointer;
          font-size: 1.5rem;
          z-index: 100; // Ensure arrows are on top of other elements
        }
      }

      .testimonial-head {
        flex-direction: column;
        align-items: flex-start;

        h2 {
          padding-left: 0;
          padding-top: 1rem;
          font-size: 3rem;
        }
      }

      .testimonials-content {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        gap: 1rem;
        position: relative;
        overflow: hidden;

        .testimonial-item {
          max-width: 100%;
          flex-shrink: 0;
          scroll-snap-align: center;
          align-items: flex-start;
          text-align: left;
          padding: 0;
          box-sizing: border-box;

          .testimonial-image {
            width: 80px;
            height: 80px;
            margin-bottom: 1rem;
            margin: auto;
            max-width: 50%;
          }

          .testimonial-text {
            margin: auto;
            max-width: 70%;
            font-size: 0.75rem;
            line-height: 1.4rem;
            margin-bottom: 1rem;
          }

          .testimonial-author {
            .author-name {
              font-size: 1.1rem;
              margin-bottom: 0.3rem;
              margin: auto;
              max-width: 50%;
            }

            .author-title {
              font-size: 0.8rem;
            }
          }
        }
      }

      .opening-quote,
      .closing-quote {
        font-size: 3rem;
        display: none; // Hide the quotes for mobile view to avoid clutter
      }
    }
  }
}
