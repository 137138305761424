@import '../_variables.scss';
@import '../_mixins.scss';

#work {
  padding: 7rem 2rem;
  background: #000;

  .work-meta {
    max-width: 1200px;
    margin: 0 auto;

    a,
    p {
      font-size: 0.9rem;
    }

    .work-text {
      position: relative;
      margin-bottom: 5rem;

      h1 {
        font-size: 5rem;
        color: $blue-color;
        font-family: $font-special;
        margin-bottom: 1.5rem;
      }

      p {
        font-size: 1.2rem;
        color: $light-blue-color;
      }

      span {
        color: $orange-color;
      }
    }

    .work-item {
      .work-content {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 1rem;
        margin-bottom: 1rem;

        .details {
          padding: 2rem;
          color: #fff;

          h3 {
            font-size: 2rem;
          }

          a {
            display: inline-block;
            margin-top: 0.75rem;
            background: #fff;
            color: #000;
            padding: 0.7rem;

            &:hover {
              background: $blue-color;
              color: $light-blue-color;
            }
          }
        }

        .image-wrapper {
          background-position: top top !important;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          filter: grayscale(100%);
          transition: filter 0.3s ease;

          &:hover {
            filter: grayscale(0%);
          }
        }

        .link {
          padding: 2rem;
          display: flex;
          justify-content: center;
          text-align: center;

          a {
            color: #fff;
            font-size: 2rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #work {
    .work-meta {
      .work-text {
        h1 {
          font-size: 5rem;
          text-align: center;
        }

        p {
          font-size: 1.5rem;
          text-align: center;
        }
      }

      .work-item {
        .work-content {
          grid-template-rows: auto auto;

          .details {
            padding: 0.2rem;

            h3 {
              font-size: 1.8rem;
            }

            p {
              font-size: 0.9rem;
            }

            a {
              margin-top: 1rem;
              padding: 0.5rem;
              font-size: 1rem;
            }
          }

          .image-wrapper {
            width: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #work {
    .work-meta {
      .work-text {
        h1 {
          font-size: 3rem;
          text-align: center;
        }

        p {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .work-item {
        .work-content {
          grid-template-columns: 1fr;

          .details {
            padding: 0.2rem;

            h3 {
              font-size: 1.5rem;
            }

            p {
              font-size: 0.8rem;
            }

            a {
              margin-top: 1rem;
              padding: 0.5rem;
              font-size: 0.9rem;
            }
          }

          .image-wrapper {
            height: 150px;
          }
        }
      }
    }
  }
}
