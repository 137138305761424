@import '../_variables.scss';
@import '../_mixins.scss';

#services {
  // min-height: 100vh;
  background: #000;
  color: #fff;
  padding: 3rem 0 0 0;
  position: relative;

  h1 {
    font-family: $font-special;
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }

  .black-square {
    position: absolute;
    width: 20rem;
    height: 20rem;
    background-color: #000;
    border-radius: 50%;
    top: -10rem;
    left: -4rem;
    z-index: 1;
  }
  .services-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);

    > * {
      padding: 2rem;

      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        background: #fff;
        color: $orange-color;
      }
    }

    > *:last-child {
      background: none;
    }

    .title {
      z-index: 998;
      grid-column: 1 / 3;
    }

    .dev {
      grid-row: 2 / 3;
      grid-column: 2 / 4;
    }

    .seo {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
    }

    .hosting {
      grid-row: 3 / 4;
      grid-column: 3 / 5;
    }

    .graphic {
      grid-row: 4 / 5;
      grid-column: 2 / 4;
    }

    .ads {
      grid-row: 5 / 6;
      grid-column: 1 / 3;
    }

    .email {
      grid-row: 5 / 6;
      grid-column: 3 / 5;
    }
  }
}

@media (max-width: 768px) {
  #services {
    .black-square {
      width: 15rem;
      height: 15rem;
      top: -rem;
      left: -4rem;
    }

    .services-content {
      display: block;
      padding: 0 2rem;

      p {
        font-size: 0.9rem;
      }

      .dev,
      .seo,
      .hosting,
      .graphic,
      .ads,
      .email {
        padding: 0.75rem;
      }

      .title {
        text-align: center;
        h1 {
          font-size: 4rem;
          text-align: center;
        }
      }

      .image-wrapper {
        max-width: 200px;
      }
    }
  }
}
